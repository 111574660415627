/* eslint-disable import/no-anonymous-default-export */
import Blog from "./blog";
import About from "./about";
import Work from "./work";
import Prices from "./prices";
import Contact from "./contact";

var home = () => {
  return (
    <div>
      <Blog />
      <About />
      <Work />
      <Prices />
      <Contact />
    </div>
  );
};

export default home;
