import { Helmet } from "react-helmet";
import Contact from "../components/contact";

var contactPage = () => {
  const title = "Contact | Sitech Web Design";
  const description =
    "Fill out the contact form to get started with your new web design enquiry.";
  const canonical = "https://sitechwebdesign.com/contact";

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:site" content={canonical} />
        <link rel="canonical" href={canonical} />
      </Helmet>
      <Contact />
    </div>
  );
};

export default contactPage;
