var title = () => {
  return (
    <section className="section has-logo-bg is-medium" id="home">
      <div className="columns is-flex is-vcentered is-centered">
        <div className="column is-11 has-text-centered">
          <h1 className="title is-1">
            <strong>Sitech Web Design.</strong>
          </h1>
          <h2 className="subtitle is-4 is-primary">
            Passion, dedication, and a lot of coffee.
          </h2>
        </div>
      </div>
    </section>
  );
};

export default title;
