import React from "react";

var about = () => {
  return (
    <section className="section" id="about">
      <div className="columns">
        <div className="column is-half">
          <div className="left">
            <div className="columns is-vcentered is-centered">
              <div className="column has-text-centered">
                <h2 className="title is-keyword" data-aos="fade-down">
                  About.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="column no-side-padding is-half">
          <div className="right">
            <div className="columns is-vcentered is-centered">
              <div className="column no-side-padding is-11">
                <pre className="code-block is-code typewriter animated"></pre>
                <div className="is-hidden animated has-text-centered" id="me">
                  <div className="columns is-vcentered is-centered">
                    <div className="column is-full">
                      <figure className="image is-128x128 image-centered">
                        <img
                          className="is-rounded"
                          src="content/img/Sitech_Web_Design.jpg"
                          alt="Sitech Web Design"
                          title="Sitech Web Design"
                        />
                      </figure>
                      <h2 className="subtitle is-3 is-secondary">Simon</h2>
                      {/* <p style="display: none;"><span className="is-keyword">var</span> <span
                                        className="is-variable">aboutMe</span>{ = {
                                    name: <span className="is-string">'Sitech Web Design'</span>,
                                    location: <span className="is-string">'Warrington, Cheshire'</span>,
                                    skills:[<span className="is-string">'Website Design'</span>,
                                    <span className="is-string">'SEO'</span>,
                                    <span className="is-string">'.NET'</span>,
                                    <span className="is-string">'javascript'</span>,
                                    <span className="is-string">'SQL Server'</span>],
                                    img: <span className="is-string">'./Simon.jpg'</span>,
                                    showProfile: showProfile
                                    };
                                    <span className="is-variable">aboutMe</span>.showProfile();</p> */}
                      <p className="has-text-justified is-size-4-desktop is-size-6-mobile">
                        I am Simon working as{" "}
                        <span className="is-keyword">Sitech Web Design</span>, a
                        trusted web design freelancer. I can bring to you an
                        array of services to help you achieve your{" "}
                        <span className="is-keyword">business goals</span> at an{" "}
                        <span className="is-keyword">affordable price</span>
                      </p>
                      <p className="has-text-justified is-size-4-desktop is-size-6-mobile">
                        As a premier web design freelancer in{" "}
                        <span className="is-keyword">Warrington</span>, I love
                        to take on new challenges.
                      </p>
                      <p className="has-text-justified is-size-4-desktop is-size-6-mobile">
                        No matter what your requirements, no matter the size of
                        your project, I take it in full stride with full
                        transparency of the process and I will deliver a
                        solution{" "}
                        <span className="is-keyword">
                          anyone would be proud of
                        </span>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default about;
