import { Helmet } from "react-helmet";

const privacyPolicy = () => {
  const title = "Privacy Policy | Sitech Web Design";
  const description = "Privacy policy for web design by Sitech Web Design.";
  const canonical = "https://sitechwebdesign.com/privacy-policy";

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:site" content={canonical} />
        <link rel="canonical" href={canonical} />
      </Helmet>
      <div className="is-full-screen" id="privacy-policy-body">
        <section className="section">
          <div className="container">
            <h2 className="subtitle is-size-2-desktop is-size-3-mobile is-primary">
              Privacy Policy
            </h2>
            <p>
              Your privacy is important to us. It is Sitech Web Design's policy
              to respect your privacy regarding any information we may collect
              from you across our website,{" "}
              <a
                href="https://sitechwebdesign.com"
                className="is-primary"
                title="Sitech Web Design"
              >
                https://sitechwebdesign.com
              </a>
              , and other sites we own and operate.
            </p>
            <p>
              We only ask for personal information when we truly need it to
              provide a service to you. We collect it by fair and lawful means,
              with your knowledge and consent. We also let you know why we’re
              collecting it and how it will be used.
            </p>
            <p>
              We only retain collected information for as long as necessary to
              provide you with your requested service. What data we store, we’ll
              protect within commercially acceptable means to prevent loss and
              theft, as well as unauthorised access, disclosure, copying, use or
              modification.
            </p>
            <p>
              We don’t share any personally identifying information publicly or
              with third-parties, except when required to by law.
            </p>
            <p>
              Our website may link to external sites that are not operated by
              us. Please be aware that we have no control over the content and
              practices of these sites, and cannot accept responsibility or
              liability for their respective privacy policies.
            </p>
            <p>
              You are free to refuse our request for your personal information,
              with the understanding that we may be unable to provide you with
              some of your desired services.
            </p>
            <p>
              Your continued use of our website will be regarded as acceptance
              of our practices around privacy and personal information. If you
              have any questions about how we handle user data and personal
              information, feel free to contact us.
            </p>
            <p>This policy is effective as of 22 July 2019.</p>
            <p>
              Generated by{" "}
              <a
                title="Privacy Policy Template Generator"
                href="https://getterms.io/"
                className="is-primary"
              >
                GetTerms.io
              </a>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default privacyPolicy;
