const notFound = () => {
  return (
    <div>
      <section className="section has-text-centered">
        <p className="subtitle is-size-1-desktop is-size-2-mobile is-primary has-text-centered">
          404 - Not Found.
        </p>
        <p className="subtitle is-size-3-desktop is-size-4-mobile is-white has-text-centered">
          Hmm, we can't seem to find that page. <br />
        </p>
        <a
          href="/"
          className="subtitle is-size-3-desktop is-size-4-mobile is-primary has-text-centered"
          title="Home"
        >
          Click here to return to the home page.
        </a>
      </section>
    </div>
  );
};

export default notFound;
