var contact = () => {
  return (
    <section className="section" id="contact">
      <div className="columns">
        <div className="column is-half">
          <div className="left">
            <div className="columns is-vcentered is-centered">
              <div className="column">
                <h2 className="title has-text-centered" data-aos="fade-up">
                  Contact.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="column no-side-padding is-half">
          <div className="right">
            <div className="columns is-vcentered is-centered">
              <div className="column is-11">
                <div className="container">
                  <form
                    className="form animated"
                    id="contact-form"
                    data-aos="zoom-out"
                  >
                    <div className="columns">
                      <div className="column">
                        <div className="field">
                          <div className="control">
                            <div className="form-group">
                              <label
                                htmlFor="contact-name"
                                className="form-label is-large"
                              >
                                Enter name
                              </label>
                              <input
                                className="input is-large form-input"
                                type="text"
                                id="contact-name"
                                name="contact-name"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <div className="field">
                          <div className="control">
                            <div className="form-group">
                              <label
                                htmlFor="contact-email"
                                className="form-label is-large"
                              >
                                Enter email
                              </label>
                              <input
                                className="input is-large form-input"
                                type="email"
                                id="contact-email"
                                name="contact-email"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <div className="field">
                          <div className="control">
                            <div className="form-group">
                              <label
                                htmlFor="contact-message"
                                className="form-label is-large"
                              >
                                Your message
                              </label>
                              <div
                                className="fake-textarea is-large form-input"
                                contentEditable="true"
                                data-placeholder="Your message"
                                id="contact-message"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <div id="recaptcha"></div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <div className="field">
                          <div className="control has-text-centered">
                            <button
                              type="button"
                              id="contact-send"
                              className="button is-primary is-outlined is-large"
                            >
                              Send&nbsp;
                              <i className="far fa-paper-plane"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <h2
                    id="contact-message-sent"
                    className="subtitle animated is-hidden is-size-4-tablet is-size-5-mobile is-primary"
                  >
                    Your message has been sent and we will get back to you as
                    soon as possible. Thank you for contacting Sitech Web
                    Design.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default contact;
