var footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="columns is-vcentered is-centered">
          <div className="column is-half">
            <div className="columns is-vcentered">
              <div className="column has-text-centered">
                <h2 className="subtitle is-size-3-desktop is-size-4-mobile is-primary">
                  Follow Us.
                </h2>
                <div className="columns is-vcentered is-centered is-mobile">
                  <div className="column has-text-centered">
                    <a
                      href="https://www.facebook.com/sitechwebdesign/"
                      className="is-primary-hover"
                      target="_blank"
                      title="Facebook | Sitech Web Design"
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook-f fa-2x"></i>
                    </a>
                  </div>
                  <div className="column has-text-centered">
                    <a
                      href="https://twitter.com/sitech_web"
                      className="is-primary-hover"
                      target="_blank"
                      title="Twitter | Sitech Web Design"
                      rel="noreferrer"
                    >
                      <i className="fab fa-twitter fa-2x"></i>
                    </a>
                  </div>
                  <div className="column has-text-centered">
                    <a
                      href="/"
                      className="is-primary-hover"
                      target="_blank"
                      title="Instagram | Sitech Web Design"
                      rel="noreferrer"
                    >
                      <i className="fab fa-instagram fa-2x"></i>
                    </a>
                  </div>
                  <div className="column has-text-centered">
                    <a
                      href="https://www.youtube.com/channel/UCJBm7u4HSIjSK4b6AzqCTlA"
                      className="is-primary-hover"
                      target="_blank"
                      title="YouTube | Sitech Web Design"
                      rel="noreferrer"
                    >
                      <i className="fab fa-youtube fa-2x"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="column has-text-centered ">
                <h2 className="subtitle is-size-3-desktop is-size-4-mobile is-primary">
                  Information.
                </h2>
                <ul>
                  <li>
                    <a
                      className="is-primary-hover underline"
                      href="/terms-of-service"
                      target="_self"
                      title="Terms of Service"
                    >
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a
                      className="is-primary-hover underline"
                      href="/privacy-policy"
                      title="Privacy Policy"
                      target="_self"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      className="is-primary-hover underline"
                      href="/sitemap"
                      target="_self"
                      title="Sitemap"
                    >
                      Sitemap
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default footer;
