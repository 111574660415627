import Home from "./pages/home";
import Title from "./components/title";
import Footer from "./components/footer";
import Blog from "./pages/blog";
import About from "./pages/about";
import Work from "./pages/work";
import Prices from "./pages/pricing";
import Contact from "./pages/contact";
import Menu from "./components/menu";
import TAndCs from "./pages/termsAndConditions";
import PrivacyPolicy from "./pages/privacyPolicy";
import NotFound from "./pages/not-found";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

var App = () => {
  return (
    <Router>
      <Menu />
      <Title />
      <Switch>
        <Route path="/blog" component={Blog} />
        <Route path="/news" component={Blog} />
        <Route path="/about" component={About} />
        <Route path="/work" component={Work} />
        <Route path="/pricing" component={Prices} />
        <Route path="/contact" component={Contact} />
        <Route path="/terms-of-service" component={TAndCs} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/" component={Home} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
