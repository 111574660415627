var work = () => {
  return (
    <section className="section" id="my-work">
      <div className="columns">
        <div className="column is-half">
          <div className="left">
            <div className="columns is-vcentered is-centered">
              <div className="column has-text-centered">
                <h2 className="title">Work.</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="column no-side-padding is-half" id="my-work-right">
          <div className="right">
            <div
              className="columns is-vcentered is-centered is-full-screen has-bg-img is-mobile"
              id="work-warrington-basketball-club"
              data-aos="zoom-in-left"
            >
              <div className="column is-one-quarter-desktop is-half-mobile has-text-centered">
                <a
                  href="https://warringtonbasketballclub.co.uk/"
                  target="_blank"
                  className="animated-button thar-three"
                  title="Warrington Basketball Club"
                  rel="noreferrer"
                >
                  Warrington Basketball Club
                </a>
              </div>
            </div>
            <div
              className="columns is-vcentered is-centered is-full-screen has-bg-img is-mobile"
              id="work-lovely-gifts-company"
              data-aos="zoom-in-left"
            >
              <div className="column is-one-quarter-desktop is-half-mobile has-text-centered">
                <a
                  href="https://lovelygiftscompany.com/"
                  target="_blank"
                  className="animated-button thar-three"
                  title="Lovely Gifts Company"
                  rel="noreferrer"
                >
                  Lovely Gifts Company
                </a>
              </div>
            </div>
            <div
              className="columns is-vcentered is-centered is-full-screen has-bg-img is-mobile"
              id="work-warrington-ironing-service"
              data-aos="zoom-in-left"
            >
              <div className="column is-one-quarter-desktop is-half-mobile has-text-centered">
                <a
                  href="https://warringtonironingservice.sitechwebdesign.com/"
                  target="_blank"
                  className="animated-button thar-three"
                  title="Warrington Ironing Service"
                  rel="noreferrer"
                >
                  Warrington Ironing Service
                </a>
              </div>
            </div>
            <div
              className="columns is-vcentered is-centered is-full-screen has-bg-img is-mobile"
              id="work-waterfront-surf-shop"
              data-aos="zoom-in-left"
            >
              <div className="column is-one-quarter-desktop is-half-mobile has-text-centered">
                <a
                  href="https://waterfrontsurfshop.sitechwebdesign.com/"
                  target="_blank"
                  className="animated-button thar-three"
                  title="Waterfront Surf Shop"
                  rel="noreferrer"
                >
                  Waterfront Surf Shop
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default work;
