import { Helmet } from "react-helmet";
import About from "../components/about";

var aboutPage = () => {
  const title = "About | Sitech Web Design";
  const description = "I am Simon working as Sitech Web Design, a trusted web design freelancer based in Warrington, Cheshire";
  const canonical = "https://sitechwebdesign.com/about"
  
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:site" content={canonical} />
        <link rel="canonical" href={canonical} />
      </Helmet>
      <About />
    </div>
  );
};

export default aboutPage;
