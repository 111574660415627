/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import { LocalStoragePosts } from "../classes/LocalStoragePosts";

const apiUrl = "https://blog-editor-api.herokuapp.com/";

const Blog = ({ numberOfArticles = 3 }: { numberOfArticles?: number }) => {
  const [posts, setPosts] = useState<any[]>([]);

  const getBlogPosts = async () => {
    const localStoragePostsSerialized = localStorage.getItem("blog-posts");
    if (localStoragePostsSerialized) {
      const localStoragePosts: LocalStoragePosts = JSON.parse(
        localStoragePostsSerialized
      );
      if (moment(localStoragePosts.expiration).isSameOrAfter(moment())) {
        setPosts(JSON.parse(localStoragePosts.data));
        return;
      }
    }

    const resp = await axios.get("/", { baseURL: apiUrl });
    if (resp && resp.data && resp.data) {
      const sortedPosts = resp.data.sort((a: any, b: any) => {
        if (a.dateCreated > b.dateCreated) return -1;
        if (b.dateCreated > a.dateCreated) return 1;
        else return 0;
      });
      const apiPosts = sortedPosts.slice(0, numberOfArticles);
      const newLocalStoragePosts: LocalStoragePosts = {
        expiration: moment().add(1, "weeks").toDate(),
        data: JSON.stringify(apiPosts),
      };
      localStorage.setItem("blog-posts", JSON.stringify(newLocalStoragePosts));
      setPosts(apiPosts);
    }
  };

  useEffect(() => {
    getBlogPosts();
  }, []);

  return (
    <section className="section" id="blog">
      <div className="columns">
        <div className="column is-half">
          <div className="left">
            <div className="columns is-vcentered is-centered">
              <div className="column has-text-centered">
                <h2 className="title">Blog.</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="column no-side-padding is-half" id="blog-right">
          <div className="right">
            {posts.map((p, i) => (
              <div
                className="columns is-vcentered is-centered is-mobile"
                data-aos="fade-down"
                key={i}
              >
                <div className="column is-11-desktop reset-this">
                  <div dangerouslySetInnerHTML={{ __html: `${p.body}` }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
