var prices = () => {
  return (
    <section className="section is-medium" id="prices">
      <div className="container has-text-centered">
        <div className="columns is-vcentered is-centered">
          <div className="column has-text-centered">
            <h2 className="title is-primary" data-aos="flip-left">
              Pricing.
            </h2>
          </div>
        </div>

        <div className="columns is-vcentered is-centered">
          <div className="column">
            <div
              className="card"
              data-aos="zoom-in"
              data-aos-delay="0"
              id="pricing-lite"
            >
              <div className="card-header">
                <h2 className="card-header-title subtitle is-size-2-desktop is-size-3-mobile is-centered">
                  Lite.
                </h2>
              </div>
              <div className="card-content is-green-bg">
                <h2 className="subtitle is-size-2-desktop is-size-3-mobile">
                  £299
                </h2>
              </div>

              <div className="card-footer">
                <div className="card-footer-item">
                  <ul className="sectioned-list">
                    <li>4 Page HTML &amp; Website</li>
                    <li>Mobile Friendly</li>
                    <li>SEO Optimised</li>
                    <li>Optimised for performance using ReactJS</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div
              className="card center"
              data-aos="zoom-in"
              data-aos-delay="100"
              id="pricing-full"
            >
              <div className="card-header">
                <h2 className="card-header-title subtitle is-size-2-desktop is-size-3-mobile is-centered">
                  Full.
                </h2>
              </div>
              <div className="card-content is-green-bg">
                <h2 className="subtitle is-size-2-desktop is-size-3-mobile">
                  £599
                </h2>
              </div>

              <footer className="card-footer">
                <div className="card-footer-item">
                  <ul className="sectioned-list">
                    <li>8 Page HTML Website</li>
                    <li>1 Social Media Account Setup</li>
                    <li>Mobile Friendly</li>
                    <li>SEO Optimised</li>
                    <li>Optimised for performance using ReactJS</li>
                  </ul>
                </div>
              </footer>
            </div>
          </div>
          <div className="column">
            <div
              className="card center"
              data-aos="zoom-in"
              data-aos-delay="100"
              id="pricing-wordpress"
            >
              <div className="card-header">
                <h2 className="card-header-title subtitle is-size-2-desktop is-size-3-mobile is-centered">
                  Wordpress.
                </h2>
              </div>
              <div className="card-content is-green-bg">
                <h2 className="subtitle is-size-2-desktop is-size-3-mobile">
                  From £799
                </h2>
              </div>

              <footer className="card-footer">
                <div className="card-footer-item">
                  <ul className="sectioned-list">
                    <li>Wordpress website</li>
                    <li>Branded theme</li>
                    <li>Mobile Friendly</li>
                    <li>SEO Optimised</li>
                  </ul>
                </div>
              </footer>
            </div>
          </div>
          <div className="column">
            <div
              className="card"
              data-aos="zoom-in"
              data-aos-delay="200"
              id="pricing-seo"
            >
              <div className="card-header">
                <h2 className="card-header-title subtitle is-size-2-desktop is-size-3-mobile is-centered">
                  SEO.
                </h2>
              </div>
              <div className="card-content is-green-bg">
                <h2 className="subtitle is-size-2-desktop is-size-3-mobile">
                  £185 p/m
                </h2>
              </div>
              <footer className="card-footer">
                <div className="card-footer-item">
                  <ul className="sectioned-list">
                    <li>On going SEO</li>
                    <li>Monthly Site Audit</li>
                    <li>Optimize your website </li>
                    <li>Website Diagnosis</li>
                  </ul>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default prices;
